import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { getAccessToken } from "../Utils/Utils";

interface AssignMaterialModalProps {
  show: boolean;
  onHide: () => void;
  type: string;
  setAssignedSuccess?: (status: boolean) => void;
  setAssignedFailed?: (status: boolean) => void;
  handleSubmit: (event: React.FormEvent) => void;
  selectedOptions: any[];
  setSelectedOptions: (options: any[]) => void;
  loading: boolean;
}

const AssignMaterialModal: React.FC<AssignMaterialModalProps> = (props) => {
  const {
    show,
    onHide,
    type,
    setAssignedSuccess,
    setAssignedFailed,
    handleSubmit,
    selectedOptions,
    setSelectedOptions,
    loading,
  } = props;
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(false);

  useEffect(() => {
    const fetchPatients = async () => {
      setLoadingOptions(true);
      try {
        const response = await axios.get(
          `${
            process.env.REACT_APP_SOPHYAPP_API_URL
          }/de/react/${getAccessToken()}/patient/get_account_patients`
        );
        const newOptions = response?.data?.map((patient: any) => ({
          value: (patient as any)?.id,
          label: (patient as any)?.name,
        }));
        setOptions(newOptions as any);
      } catch (error) {
        console.error("Error fetching patients:", error);
      } finally {
        setLoadingOptions(false);
      }
    };

    const fetchPrograms = async () => {
      try {
        const response = await axios.get(
          `${
            process.env.REACT_APP_SOPHYAPP_API_URL
          }/de/react/${getAccessToken()}/program/get_programs_simplified`
        );
        const newOptions = response?.data?.map((program: any) => ({
          value: (program as any)?.id,
          label: `${program?.name} (${program?.patient_name})`,
        }));
        setOptions(newOptions as any);
      } catch (error) {
        console.error("Error fetching programs:", error);
      }
    };

    if (type === "patient" || type === "mail") {
      fetchPatients();
    }
    if (type === "program") {
      fetchPrograms();
    }
  }, [type]);

  useEffect(() => {
    setSelectedOptions([]);
  }, [type, setSelectedOptions]);

  const loadingOptionsLabel = [{ label: "Loading...", value: "" }];
  console.log("SelectedOptions: ", selectedOptions);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {type === "mail" && t("general.assignMailMaterial")}
            {type === "patient" && t("general.assignPatientMaterial")}
            {type === "program" && t("general.assignProgramMaterial")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("materials.list")}</Form.Label>
            <Select
              isMulti
              name="materials"
              defaultValue={selectedOptions}
              options={loadingOptions ? loadingOptionsLabel : options}
              className="basic-multi-select"
              classNamePrefix="select"
              required
              onChange={(newValue, actionMeta) =>
                setSelectedOptions(newValue as any)
              }
              isDisabled={loadingOptions}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button className="bs-button-color" type="submit" disabled={loading}>
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              t("profile.save")
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AssignMaterialModal;
