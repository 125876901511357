import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import axios from "axios";
import { getAccessToken } from "../Utils/Utils.js";
import { useTranslation } from "react-i18next";
import {
  deleteAttachment,
  getAttachments,
  getAttachmentUrl,
} from "../../Api/api";

interface Attachment {
  id: string;
  title: string;
}

interface AttachmentListModalProps {
  show: boolean;
  onHide: () => void;
  patientId?: string;
  programId?: any;
}

const AttachmentListModal: React.FC<AttachmentListModalProps> = ({
  show,
  onHide,
  patientId,
  programId,
}) => {
  const { t } = useTranslation();
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const type = patientId ? "patient" : "program";

  useEffect(() => {
    const fetchAttachments = async () => {
      try {
        const token = getAccessToken();
        if (type === "patient" && !patientId) {
          console.error("Patient ID is required for patient attachments");
          return;
        }
        const data =
          type === "patient"
            ? await getAttachments(patientId!, type, token)
            : await getAttachments(programId!, type, token);
        setAttachments(data);
      } catch (error) {
        console.error("Error fetching attachments", error);
      }
    };

    if (show) {
      fetchAttachments();
    }
  }, [show, patientId, programId, type]);

  const handleOpenAttachment = async (attachmentId: string) => {
    try {
      const token = getAccessToken();
      const url = await getAttachmentUrl(attachmentId, token);
      console.log("URL", url);
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error opening attachment", error);
    }
  };

  const handleDelete = async (attachmentId: string) => {
    try {
      const token = getAccessToken();
      await deleteAttachment(attachmentId, type, token, patientId, programId);
      setAttachments(attachments.filter((att) => att.id !== attachmentId));
    } catch (error) {
      console.error("Error deleting attachment", error);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          {t(
            type === "patient" ? "patients.attachments" : "programs.attachments"
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {attachments.length === 0 ? (
          <p>
            {t(
              type === "patient"
                ? "patients.noAttachments"
                : "programs.noAttachments"
            )}
          </p>
        ) : (
          <ListGroup>
            {attachments.map((attachment) => (
              <ListGroup.Item key={attachment.id}>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOpenAttachment(attachment.id)}
                >
                  {attachment.title}
                </span>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => handleDelete(attachment.id)}
                  style={{ float: "right" }}
                >
                  {t(
                    type === "patient" ? "patients.delete" : "programs.delete"
                  )}
                </Button>
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          {t("patients.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AttachmentListModal;
