import { useState, useEffect, useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Programs.css";
import { useParams, useLocation, useNavigate, Link } from "react-router-dom";
import Loader from "../Utils/Loader";
import { useAxiosGet } from "../../Hooks/HttpRequests";
import {
  getAccessToken,
  isActive,
  getEndDateOfProgram,
  programInterval,
  getTargetName,
} from "../Utils/Utils.js";
import { ExerciseCard } from "./ExerciseCard";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import ProgressBar from "react-bootstrap/ProgressBar";
import axios from "axios";
import parse from "html-react-parser";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { AssignPatientModal } from "../Utils/Modals";
import { useTranslation } from "react-i18next";
import Alert from "react-bootstrap/Alert";
import { AccountContext } from "../Contexts/Context";
import Grid4x4RoundedIcon from "@mui/icons-material/Grid4x4Rounded";
import IconButton from "@mui/material/IconButton";
import EmailIcon from "@mui/icons-material/Email";
import PrintIcon from "@mui/icons-material/Print";
import PublishIcon from "@mui/icons-material/Publish";
import AttachmentIcon from "@mui/icons-material/Attachment";
import AttachFileModal from "../Modals/AttachFileModal";
import {
  MaterialCardButton,
  IconWrapper,
} from "../../Assets/commonStyles/styles";

function renderExercises(exercises, id) {
  let content = null;
  let exerciseContent = [];
  for (let i = 0; i < exercises?.length; i++) {
    exerciseContent.push(
      <ExerciseCard exercise={exercises[i]} key={exercises[i].id} />
    );
  }
  return exerciseContent;
}

function getProgramSessions(exercises, id) {
  let sessions = [];
  for (let i = 0; i < exercises?.length; i++) {
    if (exercises[i].id == id) {
      for (let j = 0; j < exercises[i].sessions?.length; j++) {
        {
          sessions.push(exercises[i].sessions[j]);
        }
      }
    }
  }
  return sessions;
}

function setExerciseLayout(data, columns, grid) {
  let content_block = [];
  let content = null;
  let large = 6;
  let xlarge = 3;
  if (grid) {
    large = grid;
    xlarge = grid;
  }
  for (let i = 0; i < data?.length; i += columns) {
    content = (
      <Row className="program-exercise-row">
        <Col xs={12} sm={6} md={6} lg={large} xxl={xlarge}>
          {data[i]}
        </Col>
        {(() => {
          if (data[i + 1] !== undefined && columns >= 2) {
            return (
              <Col xs={12} sm={6} md={6} lg={large} xxl={xlarge}>
                {data[i + 1]}
              </Col>
            );
          } else {
          }
        })()}
        {(() => {
          if (data[i + 2] !== undefined && columns >= 3) {
            return (
              <Col xs={12} sm={6} md={6} lg={large} xxl={xlarge}>
                {data[i + 2]}
              </Col>
            );
          }
        })()}
        {(() => {
          if (data[i + 3] !== undefined && columns >= 4) {
            return (
              <Col xs={12} sm={6} md={12} lg={large} xxl={xlarge}>
                {data[i + 3]}
              </Col>
            );
          }
        })()}
      </Row>
    );
    content_block.push(content);
  }
  return content_block;
}

function getBootstrapBreakpoint() {
  var w = window.innerWidth;
  return w < 400 ? 1 : w < 900 ? 2 : w < 1440 ? 2 : 4;
}

function sendFeedback(lvl, feedbacktext, id) {
  const url_feedback =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    "/de/v2/" +
    getAccessToken() +
    "/program_session.json";
  var params = new FormData();
  params.append("program_session[pain_level]", lvl);
  params.append("program_session[program_id]", id);
  params.append("program_session[program_feedback", feedbacktext);
  axios
    .post(url_feedback, params)
    .then(function (response) {
      //improve via state
      window.location.reload();
    })
    .catch(function (error) {});
}

function publishProgram(id, setAlertFailed, setAlertSuccess, applicationName) {
  const url =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    "/de/react/" +
    getAccessToken() +
    "/program/publish_program.json";
  var params = new FormData();
  params.append("program_id", id);
  params.append("style", applicationName);
  axios
    .post(url, params)
    .then(function (response) {
      setAlertSuccess(true);
    })
    .catch(function (error) {
      setAlertFailed(true);
    });
}

function deleteProgram(id) {
  if (
    window.confirm("Sind Sie sicher, dass Sie das Programm löschen möchten?")
  ) {
    const url =
      process.env.REACT_APP_SOPHYAPP_API_URL +
      "/de/react/" +
      getAccessToken() +
      "/program/delete_program.json";
    var params = new FormData();
    params.append("program_id", id);
    axios
      .post(url, params)
      .then(function (response) {
        window.location.assign("/dashboard");
      })
      .catch(function (error) {});
  }
}

function archiveProgram(id) {
  if (
    window.confirm(
      "Sind Sie sicher, dass Sie das Programm archivieren möchten?"
    )
  ) {
    const url =
      process.env.REACT_APP_SOPHYAPP_API_URL +
      "/de/react/" +
      getAccessToken() +
      "/program/archive_program.json";
    var params = new FormData();
    params.append("program_id", id);
    axios
      .post(url, params)
      .then(function (response) {
        window.location.assign("/dashboard");
      })
      .catch(function (error) {});
  }
}

function workoutDoneForToday(last_session) {
  let today = new Date();

  if (
    last_session != null &&
    last_session.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0)
  ) {
    return true;
  }
  return false;
}

const headers = {
  "Content-Type": "application/pdf",
  Accept: "application/pdf",
};

function Program() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const patientName =
    location.state && location.state.patientName
      ? location.state.patientName
      : null;
  if (location.showSuccessAlert) {
  }
  let language = "de";
  if (localStorage.getItem("i18nextLng")) {
    language = localStorage.getItem("i18nextLng");
  }
  const url =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    `/${language}/react/${getAccessToken()}/program/get_program/${id}`;
  const [modalShow, setModalShow] = useState(false);
  const [modalProgram, setModalProgramShow] = useState(false);
  const [alertShow, setAlertShow] = useState(true);
  const [modalAssignPatient, setModalAssignPatient] = useState(false);
  const [assignedFailed, setAssignedFailed] = useState(false);
  const [assignedSuccess, setAssignedSuccess] = useState(false);
  const [publishFailed, setPublishFailed] = useState(false);
  const [publishSuccess, setPublishSuccess] = useState(false);
  const { userData, applicationName } = useContext(AccountContext);
  const [patients, setPatients] = useState();
  const [isFetchingPatients, setIsFetchingPatients] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentColumns, setCurrentColumns] = useState(
    getBootstrapBreakpoint()
  );
  const [initial, setInitial] = useState(true);
  const [exerciseDataRow, setExerciseDataRow] = useState();
  const [showAttachFileModal, setShowAttachFileModal] = useState();
  const [type, setType] = useState("program");
  const [selectedProgram, setSelectedProgram] = useState();
  // const [patients, setPatients] = useState();

  let program = useAxiosGet(url);
  let exercises = useAxiosGet(url);
  let sorted_program_sessions;
  let content = null;

  const handleClick = async (e) => {
    let last_session = sorted_program_sessions[0].created_at;
    let today = new Date();
    if (workoutDoneForToday(last_session)) {
      alert("Maximal eine Session pro Tag erlaubt");
    } else {
      setModalShow(true);
    }
  };

  function assignPatient(id, setModal) {
    fetchPatients();
    setModal(true);
  }

  //restructure function and outsource to Api/api.js
  const fetchPatients = async () => {
    setIsFetchingPatients(true);
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_SOPHYAPP_API_URL
        }/de/react/${getAccessToken()}/patient/get_account_patients`
      );
      setPatients(response.data);
    } catch (error) {
      console.error("Error fetching patients:", error);
    } finally {
      setIsFetchingPatients(false);
    }
  };

  const editProgram = async (program, patientName) => {
    scrollToTop();
    navigate("/programs/create", {
      state: {
        program_data_edit: program,
        program_update: true,
        account_patient_id: program.account_patient_id,
        patient_name: patientName,
      },
    });
  };

  const recycleProgram = async (program) => {
    scrollToTop();

    navigate("/programs/create", {
      state: {
        program_data_edit: program,
        program_update: false,
        program_recycle: true,
        account_patient_id: program.account_patient_id,
      },
    });
  };

  const scrollToTop = async () => {
    const scrollContainer = document.querySelector(".program-overview");
    if (scrollContainer) {
      scrollContainer.scrollTo({
        top: 0,
      });
    }
  };

  const printProgram = async (program) => {
    setIsLoading(true);
    let language = "de";
    if (localStorage.getItem("i18nextLng")) {
      language = localStorage.getItem("i18nextLng");
    }
    const printProgramUrl =
      process.env.REACT_APP_SOPHYAPP_API_URL +
      `/${language}/react/${getAccessToken()}/program/print_program`;
    axios
      .get(printProgramUrl, {
        headers: headers,
        params: {
          program_id: program.id,
          style: applicationName,
        },
        responseType: "blob",
      })
      .then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        // window.open(fileURL);
        // Create an anchor element and set its href attribute to the file URL
        const link = document.createElement("a");
        link.href = fileURL;
        link.target = "_blank"; // Optional: open the file in a new tab
        link.download = "program.pdf"; // Optional: set a default file name for the download

        // Append the link to the document body and trigger a click event
        document.body.appendChild(link);
        link.click();

        // Clean up: remove the link from the document after the click event
        document.body.removeChild(link);
      })
      .finally(() => {
        setIsLoading(false); // Stop the loading indicator
      });
  };

  const sendProgramPerMail = async (program) => {
    setIsLoading(true);
    const sendProgramPerMailUrl =
      process.env.REACT_APP_SOPHYAPP_API_URL +
      `/de/react/${getAccessToken()}/program/send_program_per_mail`;
    var params = new FormData();
    params.append("program_id", program.id);
    params.append("style", applicationName);
    axios
      .post(sendProgramPerMailUrl, params)
      .then((response) => {
        alert("Programm wurde per Mail versendet");
      })
      .catch((error) => {
        alert("Programm konnte nicht per Mail versendet werden");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const setGridView = async (data) => {
    setCurrentColumns((prevColumns) => {
      const newColumns = prevColumns < 4 ? prevColumns + 1 : 2;
      const grid = newColumns === 2 ? 6 : newColumns === 3 ? 4 : 3;
      setExerciseDataRow(setExerciseLayout(data, newColumns, grid));
      return newColumns;
    });
  };

  let rowCount = 0;

  const { t, i18n } = useTranslation();

  let feedback_text = t("feedback.program-today");
  let disabled = "";

  useEffect(() => {
    if (assignedSuccess) {
      setTimeout(() => {
        setAssignedSuccess(false);
      }, 3000);
    }
  }, [assignedSuccess]);

  useEffect(() => {
    if (assignedFailed) {
      setTimeout(() => {
        setAssignedFailed(false);
      }, 3000);
    }
  }, [assignedFailed]);

  if (program.error) {
    content = <div>Programm nicht gefunden</div>;
  }

  if (program.loading) {
    content = <Loader />;
  }

  if (program.data) {
    let exercise_data = renderExercises(program.data[0].exercises, id);
    let exercise_data_row = setExerciseLayout(
      exercise_data,
      getBootstrapBreakpoint()
    );
    // toDo solve multiple render without initial
    if (initial) {
      setInitial(false);
      setExerciseDataRow(exercise_data_row);
    }

    let status_program = isActive(
      program.data[0].created_at,
      program.data[0].duration
    );

    let status;
    let variant;
    if (status_program == true) {
      status = "active";
      variant = "success";
    } else {
      status = "inactive";
      variant = "danger";
    }
    let program_sessions = getProgramSessions(program.data[0].exercises, id);
    sorted_program_sessions = program_sessions
      ?.map((obj) => {
        return { ...obj, created_at: new Date(obj.created_at) };
      })
      .sort((a, b) => b.created_at - a.created_at);
    let program_done_button, program_done_button_mobile;
    if (status == "active") {
      if (workoutDoneForToday(sorted_program_sessions[0]?.created_at)) {
        disabled = "btn-disabled";
        feedback_text = t("feedback.program-today-done");
      }
      program_done_button = (
        <Row className="program-function">
          <Col>
            <center>
              <button
                type="submit"
                className={
                  "btn-feedback-program btn-feedback-program-in-properties " +
                  disabled
                }
                onClick={() => handleClick()}
                style={{ cursor: "pointer" }}
              >
                {feedback_text}
              </button>
            </center>
          </Col>
        </Row>
      );
      program_done_button_mobile = (
        <Row className="program-finished d-block d-md-none">
          <Col>
            <center>
              <button
                type="submit"
                className={"btn-feedback-program " + disabled}
                onClick={() => handleClick()}
                style={{ cursor: "pointer" }}
              >
                {feedback_text}
              </button>
            </center>
          </Col>
        </Row>
      );
    }
    let published_text = program.data[0].published
      ? t("program.again")
      : t("program.publish");
    content = (
      <Container fluid className="h-100">
        <Row className="h-100">
          <Col md={4} lg={3} xl={2} className="program-properties h-md-100">
            <Row>
              <Breadcrumb className="breacrumb">
                <Link
                  to={`/patients/${program.data[0].account_patient_id}`}
                  className="breadcrumb-item"
                >
                  {" "}
                  &lt; Zurück
                </Link>
              </Breadcrumb>
            </Row>
            <Row>
              <Col lg={12} className="program-title">
                {parse(program.data[0].name)}
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={6} className="program-status">
                {status_program ? (
                  <div style={{ color: "green" }}>
                    <b>Aktiv</b>
                  </div>
                ) : (
                  <div style={{ color: "red" }}>
                    <b>(abgelaufen)</b>
                  </div>
                )}
              </Col>
              <Col lg={6} className="program-grid-view d-none d-lg-block">
                <MaterialCardButton
                  onClick={() => {
                    setShowAttachFileModal(true);
                    setType("program");
                    setSelectedProgram(program?.data[0]);
                  }}
                >
                  <IconWrapper>
                    <AttachmentIcon titleAccess="Anhang" />
                  </IconWrapper>
                </MaterialCardButton>
                <MaterialCardButton onClick={(e) => setGridView(exercise_data)}>
                  <IconWrapper>
                    <Grid4x4RoundedIcon titleAccess="Ansicht"
                    />
                  </IconWrapper>
                </MaterialCardButton>
                <IconButton
                  title={
                    program.data[0].published
                      ? t("programs.publishedAt") +
                        " " +
                        program.data[0].published_at
                      : t("programs.notPublished")
                  }
                  style={{ cursor: "default" }}
                >
                  <PublishIcon
                    fontSize="small"
                    style={{
                      color: program.data[0].published ? "green" : "lightgrey",
                    }}
                  />
                </IconButton>
                <IconButton
                  title={
                    program.data[0].printed
                      ? t("programs.printedAt") +
                        " " +
                        program.data[0].printed_at
                      : t("programs.notPrinted")
                  }
                  style={{ cursor: "default" }}
                >
                  <PrintIcon
                    fontSize="small"
                    style={{
                      color: program.data[0].printed ? "green" : "lightgrey",
                    }}
                  />
                </IconButton>
                <IconButton
                  title={
                    program.data[0].mailed
                      ? t("programs.mailedAt") + " " + program.data[0].mailed_at
                      : t("programs.notMailed")
                  }
                  style={{ cursor: "default" }}
                >
                  <EmailIcon
                    fontSize="small"
                    style={{
                      color: program.data[0].mailed ? "green" : "lightgrey",
                    }}
                  />
                </IconButton>
              </Col>
            </Row>
            <Row className={"program-progress-title-" + status}>
              <Col md={12}>
                {t("program.program-progress")}{" "}
                {(
                  (program_sessions.length /
                    (program.data[0].frequency * program.data[0].duration)) *
                  100
                ).toFixed(2)}{" "}
                %
              </Col>
            </Row>
            <Row className="program-progress-graph justify-content-md-center">
              <ProgressBar
                variant={variant}
                now={
                  (program_sessions.length /
                    (program.data[0].frequency * program.data[0].duration)) *
                  100
                }
                style={{ width: "90%" }}
                title={
                  (
                    (program_sessions.length /
                      (program.data[0].frequency * program.data[0].duration)) *
                    100
                  ).toFixed(2) + "%"
                }
              />
            </Row>
            <Row>
              <Col md={12} className="program-property">
                Ziel: <b>{getTargetName(program.data[0].goal_id)}</b>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="program-property">
                Frequenz: <b>{programInterval(program.data[0].frequency)}</b>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="program-property">
                Programmdauer: <b>{program.data[0].duration} Wochen</b>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="program-property">
                Ende des Programms:{" "}
                <b>
                  {new Date(
                    getEndDateOfProgram(
                      program.data[0].created_at,
                      program.data[0].duration
                    )
                  ).toLocaleDateString("de-DE")}
                </b>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="program-property">
                Anmerkungen:{" "}
                <b>
                  {program.data[0].description
                    ? program.data[0].description
                    : "Keine Anmerkung"}
                </b>
              </Col>
            </Row>
            {/* {program_done_button} */}
            {status_program ? (
              <>
                {isLoading && <Loader />}
                <Row className="program-action-buttons">
                  <Col>
                    <center>
                      <button
                        type="submit"
                        className={"btn-program-property-action"}
                        onClick={() =>
                          publishProgram(
                            id,
                            setPublishFailed,
                            setPublishSuccess,
                            applicationName
                          )
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {window.innerWidth <= 1400
                          ? "Freigeben"
                          : published_text}
                      </button>
                    </center>
                  </Col>
                </Row>
                <Row className="program-action-buttons">
                  <Col>
                    <center>
                      <button
                        type="submit"
                        className={"btn-program-property-action"}
                        onClick={() => printProgram(program.data[0])}
                        style={{ cursor: "pointer" }}
                      >
                        {window.innerWidth <= 1400
                          ? "PDF Druck"
                          : "Programmdruck"}
                      </button>
                    </center>
                  </Col>
                </Row>
                <Row className="program-action-buttons">
                  <Col>
                    <center>
                      <button
                        type="submit"
                        className={"btn-program-property-action"}
                        onClick={() => sendProgramPerMail(program.data[0])}
                        style={{ cursor: "pointer" }}
                      >
                        {window.innerWidth <= 1400
                          ? "Maildruck"
                          : "Per Mail senden"}
                      </button>
                    </center>
                  </Col>
                </Row>
                <Row className="program-action-buttons">
                  <Col>
                    <center>
                      <button
                        type="submit"
                        className={"btn-program-property-action"}
                        onClick={() =>
                          editProgram(program.data[0], patientName)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {window.innerWidth <= 1400
                          ? "Bearbeiten"
                          : "Programm bearbeiten"}
                        {/* <img
                          className="patient-header-btn-icon"
                          src="/images/pen.svg"
                          alt="edit patient"
                        /> */}
                      </button>
                    </center>
                  </Col>
                </Row>
                <Row className="program-action-buttons">
                  <Col>
                    <center>
                      <button
                        type="submit"
                        className={"btn-program-property-action"}
                        onClick={() => assignPatient(id, setModalAssignPatient)}
                        style={{ cursor: "pointer" }}
                      >
                        {window.innerWidth <= 1400
                          ? "Zuweisen"
                          : "Anderen Patienten zuweisen"}
                      </button>
                    </center>
                  </Col>
                </Row>
                <Row className="program-action-buttons">
                  <Col>
                    <center>
                      <button
                        type="submit"
                        className={"btn-program-property-action"}
                        onClick={() => archiveProgram(id)}
                        style={{ cursor: "pointer" }}
                      >
                        {window.innerWidth <= 1400
                          ? "Archivieren"
                          : "Programm archivieren"}
                      </button>
                    </center>
                  </Col>
                </Row>
                <Row className="program-action-buttons">
                  <Col>
                    <center>
                      <button
                        type="submit"
                        className={"btn-program-property-action-delete"}
                        onClick={() => deleteProgram(id)}
                        style={{ cursor: "pointer" }}
                      >
                        {window.innerWidth <= 1400
                          ? "Löschen"
                          : "Programm löschen"}
                      </button>
                    </center>
                  </Col>
                </Row>
              </>
            ) : (
              <Row className="program-action-buttons">
                <Col>
                  <center>
                    <button
                      type="submit"
                      className={"btn-program-property-action"}
                      onClick={() => recycleProgram(program.data[0])}
                      style={{ cursor: "pointer" }}
                    >
                      {window.innerWidth <= 1400
                        ? "Recyclen"
                        : "Programm wiederverwenden"}
                    </button>
                  </center>
                </Col>
              </Row>
            )}
          </Col>
          <Col md={8} lg={9} xl={10} className="exercise-details-wrapper">
            {exerciseDataRow}
            {/* {program_done_button_mobile} */}
          </Col>
        </Row>
        <AssignPatientModal
          show={modalAssignPatient}
          onHide={() => setModalAssignPatient(false)}
          key={program.data.name}
          program={id}
          patients={patients}
          template="false"
          setAssignedFailed={() => setAssignedFailed(true)}
          setAssignedSuccess={() => setAssignedSuccess(true)}
        ></AssignPatientModal>
        {showAttachFileModal && (
          <AttachFileModal
            show={showAttachFileModal}
            onHide={() => setShowAttachFileModal(false)}
            key={selectedProgram.name}
            programId={selectedProgram.id}
          />
        )}
        <Alert
          variant="success"
          className="alert-fixed"
          show={assignedSuccess}
          onClose={() => setAssignedSuccess(false)}
          dismissible
        >
          <Alert.Heading>{t("alerts.assign-patient-header")}</Alert.Heading>
          <p>{t("alerts.assign-patient-main")}</p>
        </Alert>
        <Alert
          variant="danger"
          className="alert-fixed"
          show={assignedFailed}
          onClose={() => setAssignedFailed(false)}
          dismissible
        >
          <Alert.Heading>
            {t("alerts.assign-patient-header-false")}
          </Alert.Heading>
          <p>{t("alerts.assign-patient-main-false")}</p>
        </Alert>
        <Alert
          variant="success"
          className="alert-fixed"
          show={publishSuccess}
          onClose={() => setPublishSuccess(false)}
          dismissible
        >
          <Alert.Heading>{t("alerts.publish-header-success")}</Alert.Heading>
          <p>{t("alerts.publish-main-success")}</p>
        </Alert>
        <Alert
          variant="danger"
          className="alert-fixed"
          show={publishFailed}
          onClose={() => publishFailed(false)}
          dismissible
        >
          <Alert.Heading>{t("alerts.publish-header-failed")}</Alert.Heading>
          <p>{t("alerts.publish-main-failed")}</p>
        </Alert>
      </Container>
    );
  }

  return <div className="h-100">{content}</div>;
}

export default Program;
