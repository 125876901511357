import { useState } from "react";

export default function useToken() {
  const removeExpiringToken = (key) => {
    localStorage.removeItem(key);
  };

  const getToken = () => {
    var object = JSON.parse(localStorage.getItem("access_token"));
    if (object == null) {
      return null;
    }
    var dateString = null;
    var now = null;
    if (object) {
      dateString = object.expiration;
      now = new Date().getTime().toString();
    }
    if (!object || now > dateString) {
      if (object.userToken !== undefined) {
        removeExpiringToken("access_token");
      }
      return null;
    }
    return object.userToken === "undefined" || object.userToken === undefined
      ? null
      : object.userToken;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    const now = Date.now();
    const expirationTime = 18000 * 1000;
    localStorage.setItem(
      "access_token",
      JSON.stringify({ userToken, expiration: now + expirationTime })
    );
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token,
  };
}
