import React, { Suspense, useEffect, useState } from "react";
import "./App.css";
import Menu from "./Views/Menu";
// import Dashboard from "./Components/Dashboard/Dashboard";
// import Preferences from "./Components/Preferences/Preferences";
import Login from "./Components/Login/Login";
import Profile from "./Components/Preferences/Profile";
import About from "./Components/Preferences/About";
import Locations from "./Components/Preferences/Locations";
import ProgramsOverview from "./Components/Programs/ProgramsOverview";
import Program from "./Components/Programs/Program";
import ProgramTemplate from "./Components/Programs/ProgramTemplate";
import Patients from "./Components/Patients/Patients";
import Exercises from "./Components/Exercises/Exercises";
import Materials from "./Pages/Materials/Materials";
import Analysis from "./Pages/Analysis/Analysis";
import Sidebar from "./Components/Preferences/Sidebar";
import Tags from "./Components/Preferences/Tags";
import Favorites from "./Components/Preferences/Favorites";
import Users from "./Components/Preferences/Users";
import User from "./Components/Permissions/User";
import Groups from "./Components/Permissions/Groups";
import Permissions from "./Components/Permissions/Permissions";
import Generals from "./Components/Preferences/Generals";
import Payment from "./Components/Preferences/Payment";
import Export from "./Components/Preferences/Export";
import Version from "./Components/Preferences/Version";
import NewProgram from "./Components/Programs/NewProgram";
import MailArchive from "./Components/Preferences/MailArchive";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import useToken from "./Components/Utils/useToken";
import { Navigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { AccountContext } from "./Components/Contexts/Context";
import { useTranslation } from "react-i18next";
import { getBrandingSettings } from "./Config/brandingConfig";

function App() {
  // const [applicationName, setApplicationName] = useState("sophyapp");
  // const [applicationImagePath, setApplicationImagePath] = useState(
  //   "/images/sa_logo_solo_4c_pos.svg"
  // );
  // // var color = "#84bd00";
  // const { t, i18n } = useTranslation();

  // const { token, setToken } = useToken();
  // const [userData, setUserData] = useState(
  //   JSON.parse(localStorage.getItem("userData"))
  // );

  // useEffect(() => {
  //   const hostname = window.location.hostname;
  //   if (hostname.endsWith("medfit.ch")) {
  //     import("./Styles/medfit.css").then(() => {});
  //     setApplicationName("medfit");
  //     setApplicationImagePath("/images/ci/medfit/medfit1.jpg");
  //     var link = document.querySelector("link[rel~='icon']");
  //     if (!link) {
  //       link = document.createElement("link");
  //       link.rel = "icon";
  //       document.head.appendChild(link);
  //     }
  //     link.href = "/images/ci/medfit/faviconV2.ico";
  //     document.title = "Langfristig therapieren!";
  //   } else if (hostname.endsWith("therapeut.medaix-exercise.de")) {
  //     import("./Styles/medaix.css").then(() => {});
  //     setApplicationName("medaix");
  //     setApplicationImagePath("/images/ci/medaix/medaix_logo.png");
  //     document.title = "Therapeut MedAix";
  //   } else {
  //     import("./Styles/variables.css").then(() => {});
  //   }
  //   if (userData && userData.language) {
  //     i18n.changeLanguage(userData.language);
  //   }
  // }, [userData]);
  const hostname = window.location.hostname;
  const brand = getBrandingSettings(hostname);

  const [applicationName, setApplicationName] = useState(brand.name);
  const [applicationImagePath, setApplicationImagePath] = useState(
    brand.imagePath
  );
  const { t, i18n } = useTranslation();
  const { token, setToken } = useToken();
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("userData"))
  );

  useEffect(() => {
    const cssPath = brand.css;
    import(`${cssPath}`).then(() => {});
    document.title = brand.title;

    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.head.appendChild(link);
    }
    link.href = brand.icon;

    if (userData && userData.language) {
      i18n.changeLanguage(userData.language);
    }
  }, [userData, brand]);

  if (!token) {
    return (
      <Login
        setToken={setToken}
        setUserData={setUserData}
        application={applicationName}
        imagePath={applicationImagePath}
      />
    );
  } else {
  }

  return (
    <AccountContext.Provider
      // value={{ userData, color, applicationName, applicationImagePath }}
      value={{ userData, applicationName, applicationImagePath }}
    >
      <Suspense fallback="loading">
        <div className="main_background">
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <Navigate to="/patients"></Navigate>
                    <Menu content={<Patients />} />
                  </>
                }
              ></Route>
              <Route
                path="/dashboard"
                element={
                  <>
                    <Menu content={<Patients />} />
                  </>
                }
              ></Route>
              <Route
                path="/patients"
                element={
                  <>
                    <Menu content={<Patients />} />
                  </>
                }
              ></Route>
              <Route
                path="/patients/:id"
                element={
                  <>
                    <Menu content={<Patients />} />
                  </>
                }
              ></Route>
              <Route
                path="/programs"
                element={
                  <>
                    <Menu content={<ProgramsOverview />} />
                  </>
                }
              ></Route>
              <Route
                path="/programs/:id"
                element={
                  <>
                    <Menu content={<Program />} />
                  </>
                }
              ></Route>
              <Route
                path="/program_template/:id"
                element={
                  <>
                    <Menu content={<ProgramTemplate />} />
                  </>
                }
              ></Route>
              <Route
                path="/programs/create"
                element={
                  <>
                    <Menu content={<NewProgram />} />
                  </>
                }
              ></Route>
              <Route
                path="/exercises"
                element={
                  <>
                    <Menu content={<Exercises />} />
                  </>
                }
              ></Route>
              {/* MATERIAL */}
              <Route
                path="/materials"
                element={
                  <>
                    <Menu content={<Materials />} />
                  </>
                }
              ></Route>
              {/* ANALYSIS */}
              <Route
                path="/analysis"
                element={
                  <>
                    <Menu content={<Analysis />} />
                  </>
                }
              ></Route>
              {/* PREFERENCES */}
              <Route
                path="/preferences/tags"
                element={
                  <>
                    <Menu
                      content={
                        <Sidebar contentdisplay={<Tags />} title="Tags" />
                      }
                    />
                  </>
                }
              ></Route>
              <Route
                path="/preferences/favorites"
                element={
                  <>
                    <Menu
                      content={
                        <Sidebar
                          contentdisplay={<Favorites />}
                          title="Favorites"
                        />
                      }
                    />
                  </>
                }
              ></Route>
              <Route
                path="/preferences/users"
                element={
                  <>
                    <Menu
                      content={
                        <Sidebar contentdisplay={<Users />} title="Users" />
                      }
                    />
                  </>
                }
              ></Route>
              <Route
                path="/preferences/users/:id"
                element={
                  <>
                    <Menu
                      content={
                        <Sidebar contentdisplay={<User />} title="User" />
                      }
                    />
                  </>
                }
              ></Route>
              <Route
                path="/permissions/groups"
                element={
                  <>
                    <Menu
                      content={
                        <Sidebar contentdisplay={<Groups />} title="Groups" />
                      }
                    />
                  </>
                }
              ></Route>
              <Route
                path="/permissions/permissions/:id"
                element={
                  <>
                    <Menu
                      content={
                        <Sidebar
                          contentdisplay={<Permissions />}
                          title="Berechtigungen"
                        />
                      }
                    />
                  </>
                }
              ></Route>
              {/* <Route
                path="/preferences/analysis"
                element={
                  <>
                    <Menu
                      content={
                        <Sidebar
                          contentdisplay={<Analysis />}
                          title="Analysis"
                        />
                      }
                    />
                  </>
                }
              ></Route> */}
              <Route
                path="/preferences/general"
                element={
                  <>
                    <Menu
                      content={
                        <Sidebar
                          contentdisplay={<Generals />}
                          title="Allgemein"
                        />
                      }
                    />
                  </>
                }
              ></Route>
              <Route
                path="/preferences/mailarchive"
                element={
                  <>
                    <Menu
                      content={
                        <Sidebar
                          contentdisplay={<MailArchive />}
                          title="Mailarchiv"
                        />
                      }
                    />
                  </>
                }
              ></Route>
              <Route
                path="/preferences/export"
                element={
                  <>
                    <Menu
                      content={
                        <Sidebar
                          contentdisplay={<Export />}
                          title="data export"
                        />
                      }
                    />
                  </>
                }
              ></Route>
              <Route
                path="/preferences/payment"
                element={
                  <>
                    <Menu
                      content={
                        <Sidebar
                          contentdisplay={<Payment />}
                          title="Zahlungsdetails"
                        />
                      }
                    />
                  </>
                }
              ></Route>
              <Route
                path="/preferences"
                element={
                  <>
                    <Navigate to="/preferences/profile"></Navigate>
                    <Menu
                      content={
                        <Sidebar contentdisplay={<Profile />} title="Profile" />
                      }
                    />
                  </>
                }
              ></Route>
              <Route
                path="/preferences/profile"
                element={
                  <>
                    <Menu
                      content={
                        <Sidebar contentdisplay={<Profile />} title="Profile" />
                      }
                    />
                  </>
                }
              ></Route>
              <Route
                path="/preferences/version"
                element={
                  <>
                    <Menu
                      content={
                        <Sidebar contentdisplay={<Version />} title="Version" />
                      }
                    />
                  </>
                }
              ></Route>
              <Route
                path="/preferences/about"
                element={
                  <>
                    <Menu
                      content={
                        <Sidebar
                          contentdisplay={<About />}
                          title="Über sophyapp"
                        />
                      }
                    />
                  </>
                }
              ></Route>
              <Route
                path="/preferences/locations"
                element={
                  <>
                    <Menu
                      content={
                        <Sidebar
                          contentdisplay={<Locations />}
                          title={t("settings.locations")}
                        />
                      }
                    />
                  </>
                }
              ></Route>
              <Route
                path="*"
                element={
                  <>
                    <Navigate to="/dashboard"></Navigate>
                    <Menu content={<Patients />} />
                  </>
                }
              ></Route>
            </Routes>
          </BrowserRouter>
        </div>
      </Suspense>
    </AccountContext.Provider>
  );
}

export default withTranslation()(App);
