import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Checkbox,
  Box,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Paper,
  FormControl,
  FormGroup,
  FormLabel,
  FormControlLabel,
} from "@mui/material";
import { getAccessToken } from "../Utils/Utils.js";
import axios from "axios";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import Alerts from "../CommonMaterial/Alerts.tsx";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  maxWidth: 400,
  margin: "auto",
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  "&.Mui-selected": {
    backgroundColor: "var(--main-color)",
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: "lightgrey",
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "var(--main-color)",
  color: theme.palette.common.white,
  "&:hover": {
    backgroundColor: "lightgrey",
  },
}));

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function PaymentMethod(handleClose) {
  const [paymentMethod, setPaymentMethod] = useState("invoice");
  const [therapists, setTherapists] = useState(1);
  const [clientSecret, setClientSecret] = useState(null);
  const { t } = useTranslation();

  const handlePaymentMethodChange = (event, method) => {
    if (method !== null) {
      setPaymentMethod(method);
    }
  };

  const handleTherapistChange = (e) => {
    setTherapists(Number(e.target.value));
  };

  return (
    <StyledPaper elevation={3}>
      <Typography variant="h4" gutterBottom>
        {t("payment.paymentMethod")}
      </Typography>

      <StyledToggleButtonGroup
        value={paymentMethod}
        exclusive
        onChange={handlePaymentMethodChange}
        fullWidth
      >
        <StyledToggleButton value="invoice" aria-label="invoice">
          {t("payment.invoice")}
        </StyledToggleButton>
        <StyledToggleButton value="credit" aria-label="credit card">
          {t("payment.creditCard")}
        </StyledToggleButton>
      </StyledToggleButtonGroup>

      <Box component="form" noValidate>
        <TextField
          fullWidth
          label="Therapeuten"
          type="number"
          value={therapists}
          onChange={handleTherapistChange}
          inputProps={{ min: 1, max: 10 }}
          margin="normal"
          disabled={paymentMethod === "credit" && clientSecret !== null}
        />

        <Typography variant="body1" gutterBottom>
          <strong>Gesamt:</strong> {therapists * 14.0} Euro exkl. Mwst pro Monat
        </Typography>

        {paymentMethod === "invoice" ? (
          <InvoiceForm therapists={therapists} handleClose={handleClose} />
        ) : (
          <CreditCardForm
            therapists={therapists}
            setClientSecret={setClientSecret}
            handleClose={handleClose}
          />
        )}
      </Box>
    </StyledPaper>
  );
}

function InvoiceForm({ therapists, handleClose }) {
  const [iban, setIban] = useState("");
  const [bankName, setBankName] = useState("");
  const [order, setOrder] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setIsFormValid(iban && bankName && order);
  }, [iban, bankName, order]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = `${
      process.env.REACT_APP_SOPHYAPP_API_URL
    }/de/react/${getAccessToken()}/settings/update_payment`;
    const params = new FormData();
    params.append("iban", iban);
    params.append("bankName", bankName);
    params.append("paymentMethod", "invoice");
    params.append("therapists", therapists);

    try {
      await axios.post(url, params);
      alert("Abo erfolgreich geändert - vielen Dank!");
      handleClose();
    } catch (error) {
      alert("Es ist ein Fehler aufgetreten: " + error.message);
    }
  };

  return (
    <>
      <TextField
        fullWidth
        label="IBAN"
        value={iban}
        onChange={(e) => setIban(e.target.value)}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Name der Bank"
        value={bankName}
        onChange={(e) => setBankName(e.target.value)}
        margin="normal"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={order}
            onChange={(e) => setOrder(e.target.checked)}
            color="primary"
          />
        }
        label="kostenpflichtig bestellen"
      />
      <Box mt={2}>
        <StyledButton
          type="submit"
          variant="contained"
          fullWidth
          disabled={!isFormValid}
          onClick={handleSubmit}
        >
          {t("payment.submit")}
        </StyledButton>
      </Box>
    </>
  );
}

function CreditCardForm({ therapists, setClientSecret, handleClose }) {
  const [isLoadingIntent, setIsLoadingIntent] = useState(false);
  const [localClientSecret, setLocalClientSecret] = useState(null);
  const { t } = useTranslation();

  const handleNext = async (e) => {
    e.preventDefault();
    setIsLoadingIntent(true);
    try {
      const urlPaymentIntent = `${
        process.env.REACT_APP_SOPHYAPP_API_URL
      }/de/react/${getAccessToken()}/settings/create_payment_intent`;
      const response = await axios.post(urlPaymentIntent, { therapists });
      if (response.data.clientSecret) {
        setLocalClientSecret(response.data.clientSecret);
        setClientSecret(response.data.clientSecret);
      } else {
        console.error("Failed to fetch client secret");
      }
    } catch (error) {
      console.error("Error fetching client secret:", error);
    } finally {
      setIsLoadingIntent(false);
    }
  };

  return (
    <>
      {!localClientSecret ? (
        <StyledButton
          type="button"
          variant="contained"
          fullWidth
          onClick={handleNext}
          disabled={isLoadingIntent}
        >
          {isLoadingIntent ? t("payment.loading") : t("payment.next")}
        </StyledButton>
      ) : (
        <Elements
          stripe={stripePromise}
          options={{ clientSecret: localClientSecret }}
        >
          <StripePaymentForm
            clientSecret={localClientSecret}
            handleClose={handleClose}
            therapists={therapists}
          />
        </Elements>
      )}
    </>
  );
}

function StripePaymentForm({ clientSecret, handleClose, therapists }) {
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentComplete, setPaymentComplete] = useState(false);

  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handlePaymentChange = (event) => {
    setPaymentComplete(event.complete);
  };

  const handleStripePayment = async (event) => {
    event.preventDefault();
    if (!stripe || !elements || !clientSecret) {
      console.error("Stripe.js has not loaded yet.");
      return;
    }

    setIsProcessing(true);

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
      });

      if (error) {
        console.error(error);
        alert("Zahlung fehlgeschlagen: " + error.message);
      } else {
        const urlDefaultMethod = `${
          process.env.REACT_APP_SOPHYAPP_API_URL
        }/de/react/${getAccessToken()}/settings/set_default_payment_method`;
        const response = await fetch(urlDefaultMethod, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            paymentMethodId: paymentIntent.payment_method,
          }),
        });

        if (response.ok) {
          const updateSubscriptionUrl = `${
            process.env.REACT_APP_SOPHYAPP_API_URL
          }/de/react/${getAccessToken()}/settings/update_subscription`;
          const subscriptionResponse = await axios.post(updateSubscriptionUrl, {
            paymentMethodId: paymentIntent.payment_method,
            therapists: therapists,
            paymentMethod: "credit",
          });
          setAlert({
            open: true,
            message:
              "Zahlung erfolgreich und als Standardzahlungsmethode gesetzt!",
            severity: "success",
          });
          handleClose();
        } else {
          setAlert({
            open: true,
            message:
              "Zahlung erfolgreich, aber Standardzahlungsmethode konnte nicht gesetzt werden.",
            severity: "error",
          });
          handleClose();
        }
      }
    } catch (error) {
      console.error("Error processing payment:", error);
      alert("Es ist ein Fehler aufgetreten: " + error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Box mt={2}>
      <PaymentElement onChange={handlePaymentChange} />
      <Box mt={2}>
        <StyledButton
          type="submit"
          variant="contained"
          fullWidth
          disabled={!stripe || !elements || isProcessing || !paymentComplete}
          onClick={handleStripePayment}
        >
          {isProcessing ? t("payment.processing") : t("payment.submit")}
        </StyledButton>
      </Box>
      <Alerts
        open={alert.open}
        message={alert.message}
        severity={alert.severity}
        onClose={() =>
          setAlert({ open: false, message: "", severity: "success" })
        }
      />
    </Box>
  );
}

export default function PaymentMethodWrapper() {
  return (
    <Elements stripe={stripePromise}>
      <PaymentMethod />
    </Elements>
  );
}
