import React, { useState, useEffect, useRef, useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import { CatalogExerciseCard } from "../Programs/ExerciseCard";
import Loader from "../Utils/Loader";
import { getAccessToken } from "../Utils/Utils.js";
import axios from "axios";
import { NewExerciseModal, ExerciseModal } from "../Utils/Modals.js";
import Spinner from "react-bootstrap/Spinner";
import useMediaQuery from "@mui/material/useMediaQuery";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { AccountContext } from "../Contexts/Context";
import Sidebar from "../Sidebar/Sidebar";

function setExerciseLayout(
  data,
  columns,
  setActiveExercise,
  setShowExerciseModal,
  setExercise,
  refProp
) {
  let content_block = [];
  let content = null;
  let exerciseCards;
  for (let i = 0; i < data?.length; i += columns) {
    exerciseCards = [];
    for (let j = 0; j < columns; j++) {
      if (i + j < data.length) {
        exerciseCards.push(
          <Col md={4} lg={3} xxl={3}>
            <CatalogExerciseCard
              exercise={data[i + j]}
              key={data[i].id}
              setActiveExercise={setActiveExercise}
              setShowExerciseModal={setShowExerciseModal}
              setExercise={setExercise}
            />
          </Col>
        );
      }
    }
    content = <Row className="program-exercise-row">{exerciseCards}</Row>;
    content_block.push(content);
  }
  return content_block;
}

function getBootstrapBreakpoint() {
  var w = window.innerWidth;
  return w <= 600 ? 2 : w < 900 ? 3 : w < 1440 ? 4 : w < 1700 ? 4 : 4;
}

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export default function Exercises(props) {
  const url =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    `/de/react/${getAccessToken()}/sources/index`;
  let content = null;
  const [showNewExerciseModal, setShowNewExerciseModal] = useState(false);
  const [showExerciseModal, setShowExerciseModal] = useState(false);
  const [showNewExerciseModalSpinner, setShowNewExerciseModalSpinner] =
    useState(false);
  const [activeExercise, setActiveExercise] = useState();
  const lastEntryRef = useRef(null);
  const isMobile = useMediaQuery("(max-width:900px)");
  const smallMobile = useMediaQuery("(max-width:500px");
  const [mobileFilters, setMobileFilters] = useState(false);
  const [favorites, setFavorites] = useState();
  const [total, setTotal] = useState();
  const { applicationName } = useContext(AccountContext);
  let language = "de";
  if (localStorage.getItem("i18nextLng")) {
    language = localStorage.getItem("i18nextLng");
  }
  const url_favorites =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    "/" +
    language +
    "/react/" +
    getAccessToken() +
    "/settings/get_favorite_groups";
  const [exercise, setExercise] = useState({
    title: "",
    steps: "",
    thumbnail: "",
    video: "",
    patient_id: "",
    body_region: "",
    difficulty: "",
    starting_position: "",
    goal: "",
    tool: "",
  });
  const [params, setParams] = useState({
    account_id: "",
    patient_id: "",
    body_region: "",
    difficulty: "",
    starting_position: "",
    goal: "",
    tool: "",
    provider: "",
    q: "",
    favorites: "",
    page: 1,
    own_exercises: false,
  });

  const handleOnChangeModal = async (target, value) => {
    setExercise({
      ...exercise,
      [target]: value,
    });
  };

  const handleOnChangeFilter = async (target, value) => {
    setParams({
      ...params,
      [target]: value,
      page: 1,
    });
  };

  const [exercises, setExercises] = useState({
    loading: false,
    data: null,
    error: false,
  });

  const onPageChange = (event, value) => {
    setParams({
      ...params,
      page: value,
    });
  };

  const scrollToTop = () => {
    const scrollContainer = document.querySelector(".program-overview");
    if (scrollContainer) {
      scrollContainer.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const { t } = useTranslation();

  useEffect(() => {
    setExercises({
      loading: true,
      data: null,
      error: false,
    });
    axios
      .get(url, {
        headers: headers,
        params: {
          account_id: params.account_id,
          patient_id: params.patient_id,
          body_region: params.body_region,
          difficulty: params.difficulty,
          starting_position: params.starting_position,
          goal: params.goal,
          tool: params.tool,
          provider: params.provider,
          q: params.q,
          favorites: params.favorites,
          page: params.page,
          own_exercises: params.own_exercises,
        },
      })
      .then((response) => {
        setExercises({
          loading: false,
          data: response.data?.sources,
          error: false,
        });
        setTotal(response.data?.total);
      })
      .catch(() => {
        setExercises({
          loading: false,
          data: null,
          error: true,
        });
      });
  }, [params, url]);

  useEffect(() => {
    scrollToTop();
  }, [params.page]);

  useEffect(() => {
    axios
      .get(url_favorites)
      .then((response) => {
        const options = response.data;
        setFavorites(options);
      })
      .catch(() => {});
  }, [url_favorites]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 1,
      }
    );

    if (lastEntryRef.current) {
      observer.observe(lastEntryRef.current);
    }

    return () => {
      if (lastEntryRef.current) {
        observer.unobserve(lastEntryRef.current);
      }
    };
  }, [lastEntryRef]);

  if (exercises.error) {
    content = <div>{t("exercises.catalogNotFound")}</div>;
  }

  if (exercises.loading) {
    content = <Loader />;
  }

  if (exercises.data) {
    let exercise_data_rows = setExerciseLayout(
      exercises.data,
      getBootstrapBreakpoint(),
      setActiveExercise,
      setShowExerciseModal,
      setExercise,
      lastEntryRef
    );
    exercise_data_rows.push(
      <Stack spacing={2}>
        <Pagination
          count={Math.ceil(total / 12)}
          color="primary"
          onChange={(event, value) => {
            onPageChange(event, value);
            scrollToTop();
          }}
          page={params.page}
        />
      </Stack>
    ); // add the div element
    content = exercise_data_rows;
  }
  var mobileHeader = (
    <div>
      <Row>
        <Col xxs={12} xs={12} className="sidebar-properties-patient-list">
          <Row>
            <Col xxs={12} className="sidebar-header-mobile">
              {t("exercises.exercises")}
            </Col>
          </Row>
          <Row>
            <Col xxs={10} xs={10} md={8}>
              <input
                type="search"
                name="q"
                className="searchbar-exercises-mobile"
                placeholder={t("exercises.search")}
                value={setParams.q}
                onChange={(e) =>
                  handleOnChangeFilter(e.target.name, e.target.value)
                }
              ></input>
            </Col>
            <Col xxs={2} xs={2} md={4}>
              <center>
                <button
                  type="submit"
                  className="btn-new-program-mobile"
                  onClick={() => setShowNewExerciseModal(true)}
                  style={{ cursor: "pointer" }}
                >
                  {smallMobile ? "+" : "Eigene Übung hochladen"}
                  <img
                    className="plus-icon"
                    src="/images/plus.svg"
                    alt="add sign"
                  />
                </button>
              </center>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col
          xs={12}
          className="filter-title-mobile"
          onClick={() => setMobileFilters(!mobileFilters)}
        >
          {t("exercises.filter")}
        </Col>
      </Row>
    </div>
  );

  var filters = (
    <Row>
      <Col
        md={12}
        className="sidebar-properties-patient-list sidebar-filter-container"
      >
        {isMobile ? null : "Filter"}
        <center>
          <Row>
            <Col md={12} className="sidebar-filter">
              <select
                name="body_region"
                value={setParams.body_region}
                onChange={(e) =>
                  handleOnChangeFilter(e.target.name, e.target.value)
                }
                className="select-box sidebar-filter-elem"
              >
                <option style={{ display: "none" }} defaultValue>
                  {t("exercises.body-region")}
                </option>
                <option value="all">alle</option>
                <option value="CORE">CORE</option>
                <option value="cervical-spine">Hws</option>
                <option value="thoracic-spine">Bws</option>
                <option value="lumbar-spine">Lws</option>
                <option value="UPPER-LIMB">OBERE EXTREMITÄT</option>
                <option value="shoulder">Schulter</option>
                <option value="elbow">Ellbogen</option>
                <option value="hand">Hand</option>
                <option value="LOWER-LIMB">UNTERE EXTREMITÄT</option>
                <option value="hip">Hüfte</option>
                <option value="knee">Knie</option>
                <option value="foot">Fuß</option>
                <option value="legs">Beine</option>
                <option value="arms">Arme</option>
                <option value="chest">Brust</option>
                <option value="body">Ganzkörper</option>
                <option value="abductor">Abduktoren</option>
                <option value="adductor">Adduktoren</option>
                <option value="back">Rücken</option>
                <option value="biceps">Bizeps</option>
                <option value="triceps">Trizeps</option>
                <option value="lungs">Lunge</option>
              </select>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="sidebar-filter">
              <select
                name="starting_position"
                value={setParams.starting_position}
                onChange={(e) =>
                  handleOnChangeFilter(e.target.name, e.target.value)
                }
                className="select-box sidebar-filter-elem"
              >
                <option style={{ display: "none" }} defaultValue>
                  {t("exercises.position")}
                </option>
                <option value="all">alle</option>
                <option value="lying">liegend</option>
                <option value="sitting">sitzend</option>
                <option value="all-fours">Vierfüßler</option>
                <option value="standing">stehend</option>
              </select>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="sidebar-filter">
              <select
                name="goal"
                value={setParams.goal}
                onChange={(e) =>
                  handleOnChangeFilter(e.target.name, e.target.value)
                }
                className="select-box sidebar-filter-elem"
              >
                <option style={{ display: "none" }} defaultValue>
                  {t("exercises.goal")}
                </option>
                <option value="all">alle</option>
                <option value="stabilisation/strength">
                  Stabilisation/Kraft
                </option>
                <option value="mobilisation">Mobilisation</option>
                <option value="coordination">Koordination</option>
                <option value="stretch">Dehnung</option>
                <option value="endurance">Ausdauer</option>
                <option value="neurodynamic">Neurodynamik</option>
              </select>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="sidebar-filter">
              <select
                name="difficulty"
                value={setParams.difficulty}
                onChange={(e) =>
                  handleOnChangeFilter(e.target.name, e.target.value)
                }
                className="select-box sidebar-filter-elem"
              >
                <option style={{ display: "none" }} defaultValue>
                  {t("exercises.difficulty")}
                </option>
                <option value="all">alle</option>
                <option value="easy">leicht</option>
                <option value="medium">mittel</option>
                <option value="hard">schwierig</option>
              </select>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="sidebar-filter">
              <select
                name="tool"
                value={setParams.tool}
                onChange={(e) =>
                  handleOnChangeFilter(e.target.name, e.target.value)
                }
                className="select-box sidebar-filter-elem"
              >
                <option style={{ display: "none" }} defaultValue>
                  {t("exercises.tool")}
                </option>
                <option value="all">alle</option>
                <option value="kettle">Kettleball</option>
                <option value="blackroll">Blackroll</option>
                <option value="theraband">Theraband</option>
                <option value="dumbbell">Kurzhantel</option>
                <option value="barbell">Langhantel</option>
                <option value="pezziball">Pezziball</option>
                <option value="others">Sonstiges</option>
                <option value="none">Kein Hilfsmittel</option>
              </select>
            </Col>
          </Row>
          {applicationName === "medaix" ? null : (
            <Row>
              <Col md={12} className="sidebar-filter">
                <select
                  name="provider"
                  value={setParams.provider}
                  onChange={(e) =>
                    handleOnChangeFilter(e.target.name, e.target.value)
                  }
                  className="select-box sidebar-filter-elem"
                >
                  <option style={{ display: "none" }} defaultValue>
                    {t("exercises.provider")}
                  </option>
                  <option value="all">alle</option>
                  <option value="sophyapp">sophyapp</option>
                  <option value="cornell">Cornell Health University</option>
                  <option value="precision">Precision Nutrition</option>
                  <option value="physio10">Physio 1.0</option>
                </select>
              </Col>
            </Row>
          )}
          <Row>
            <Col md={12} className="sidebar-filter">
              <select
                name="favorites"
                value={setParams.favorites}
                onChange={(e) =>
                  handleOnChangeFilter(e.target.name, e.target.value)
                }
                className="select-box sidebar-filter-elem"
              >
                <option style={{ display: "none" }} defaultValue>
                  {t("exercises.favorites")}
                </option>
                <option value="">alle</option>
                {favorites?.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.group_name}
                  </option>
                ))}
              </select>
            </Col>
          </Row>
        </center>
        <Row>
          <Col md={12} className="sidebar-filter" style={{ marginTop: "5px" }}>
            <input
              type="checkbox"
              name="own_exercises"
              checked={setParams.own_exercises}
              onChange={(e) =>
                handleOnChangeFilter(e.target.name, e.target.checked)
              }
            />
            <span> {t("general.ownExercises")}</span>
          </Col>
        </Row>
      </Col>
    </Row>
  );

  var sidebar = (
    <Sidebar
      setShowNewExerciseModal={setShowNewExerciseModal}
      setParams={setParams}
      handleOnChangeFilter={handleOnChangeFilter}
      filters={filters}
    />
  );

  return (
    <div className="h-100">
      <Container fluid className="h-100 sidebar-menu">
        <Row className="h-100">
          {isMobile ? mobileHeader : sidebar}
          {mobileFilters ? filters : null}
          <Col
            md={12}
            lg={10}
            xxl={10}
            xxxl={11}
            className="sb-main-content exercise-overview-main-mobile h-100 "
          >
            <Row>
              <Col
                md={12}
                className="overview-exercises exercise-overview-main-mobile"
              >
                {content}

                <div className="load-more" ref={lastEntryRef}></div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <NewExerciseModal
        show={showNewExerciseModal}
        backdrop="static"
        onHide={() => setShowNewExerciseModal(false)}
        setLoader={() => setShowNewExerciseModalSpinner(true)}
        hideLoader={() => setShowNewExerciseModalSpinner(false)}
      />
      <Spinner
        show={showNewExerciseModalSpinner}
        animation="grow"
        role="status"
        style={{
          width: "20rem",
          height: "20rem",
          color: "var(--main-color)",
          top: "30%",
          right: "45%",
          zIndex: "10000",
          display: showNewExerciseModalSpinner ? "block" : "none",
        }}
        className="spinner"
      >
        <span className="visually-hidden"></span>
      </Spinner>
      <ExerciseModal
        show={showExerciseModal}
        backdrop="static"
        exercise={activeExercise}
        exerciseData={exercise}
        handleOnChangeModal={(target, value) =>
          handleOnChangeModal(target, value)
        }
        onHide={() => setShowExerciseModal(false)}
      />
    </div>
  );
}
