import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_SOPHYAPP_API_URL + "/de/react";

export const getMaterials = async (filters: any, token: string) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/${token}/material/index`,
      {
        params: filters,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching materials:", error);
    throw error;
  }
};

export const submitMaterial = async (newMaterial: any, token: string) => {
  try {
    const formData = new FormData();
    formData.append("file", newMaterial.file);
    formData.append("title", newMaterial.title);
    formData.append("description", newMaterial.description);
    formData.append("category", newMaterial.category);

    const response = await axios.post(
      `${API_BASE_URL}/${token}/material/create`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error submitting material:", error);
    throw error;
  }
};

export const deleteMaterial = async (materialId: string, token: string) => {
  try {
    const formData = new FormData();
    formData.append("id", materialId);
    const response = await axios.post(
      `${API_BASE_URL}/${token}/material/delete_material`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting material:", error);
    throw error;
  }
};

export const updateMaterial = async (
  materialId: string,
  updatedMaterial: any,
  token: string
) => {
  try {
    const formData = new FormData();
    formData.append("file", updatedMaterial.file);
    formData.append("title", updatedMaterial.title);
    formData.append("description", updatedMaterial.description);
    formData.append("category", updatedMaterial.category);
    formData.append("id", materialId);
    const response = await axios.put(
      `${API_BASE_URL}/${token}/material/update`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating material:", error);
    throw error;
  }
};

export const publishMaterial = async (
  materialId: string,
  selectedOptions: any,
  token: string
) => {
  const ids = selectedOptions.map((option: { value: number }) => option.value);
  try {
    const response = await axios.post(
      `${API_BASE_URL}/${token}/material/publish`,
      { materialId, ids },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error publishing material:", error);
    throw error;
  }
};

export const mailMaterial = async (
  materialId: string,
  selectedOptions: any,
  token: string
) => {
  const ids = selectedOptions.map((option: { value: number }) => option.value);
  try {
    const response = await axios.post(
      `${API_BASE_URL}/${token}/material/mail`,
      { materialId, ids },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error mailing material:", error);
    throw error;
  }
};

export const addToProgram = async (
  materialId: string,
  selectedOptions: any,
  token: string
) => {
  try {
    const ids = selectedOptions.map(
      (option: { value: number }) => option.value
    );
    const response = await axios.post(
      `${API_BASE_URL}/${token}/material/add_to_program`,
      { materialId, ids },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error adding material to program:", error);
    throw error;
  }
};

export const getAttachments = async (
  patientId: string,
  type: string,
  token: string
) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/${token}/material/get_attachments/${type}/${patientId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching attachments:", error);
    throw error;
  }
};

export const getAttachmentUrl = async (attachmentId: string, token: string) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/${token}/material/get_attachment_file/${attachmentId}`
    );
    return response.data.url;
  } catch (error) {
    console.error("Error fetching attachment URL:", error);
    throw error;
  }
};

export const deleteAttachment = async (
  attachmentId: string,
  type: string,
  token: string,
  patientId?: string,
  programId?: string
) => {
  try {
    const formData = new FormData();
    formData.append("id", attachmentId);
    formData.append("type", type);
    if (patientId) {
      formData.append("patientId", patientId);
    }
    if (programId) {
      formData.append("programId", programId);
    }
    const response = await axios.post(
      `${API_BASE_URL}/${token}/material/delete_attachment_from_assignment`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting attachment:", error);
    throw error;
  }
};

// const fetchCsrfToken = async () => {
//   const response = await axios.get(`${BASE_URL}/csrf-token`);
//   return response.data.token;
// };

// const acquireAccessToken = async () => {
//   const accounts = msalInstance.getAllAccounts();
//   if (accounts.length > 0) {
//     try {
//       const response = await msalInstance.acquireTokenSilent({
//         scopes: ["openid", "profile", "User.Read", "Group.Read.All"],
//         account: accounts[0],
//       });
//       return response.accessToken;
//     } catch (error) {
//       console.error("Error acquiring token silently:", error);
//       throw error;
//     }
//   } else {
//     throw new Error("No accounts found");
//   }
// };

// export const getUserGroupsAuthorization = async (accessToken) => {
//   const response = await axios.post(`${BASE_URL}/validateUserGroups`, {
//     accessToken: accessToken,
//   });
//   return response.data;
// };

// const initializeApiClient = async (customHeaders = {}) => {
//   const csrfToken = await fetchCsrfToken();
//   // const authToken = await getAuthToken();
//   const apiClient = axios.create({
//     baseURL: BASE_URL,
//     headers: {
//       Accept: "application/json",
//       ...customHeaders,
//     },
//   });
//   apiClient.interceptors.request.use(async (config) => {
//     try {
//       const token = await acquireAccessToken();
//       config.headers.Authorization = `Bearer ${token}`;
//     } catch (error) {
//       console.error("Error setting auth token:", error);
//     }
//     if (config.method !== "get") {
//       config.headers["X-CSRF-Token"] = csrfToken;
//     }
//     return config;
//   });

//   return apiClient;
// };

// export const fetchData = async (endpoint, context = "") => {
//   const customHeaders = {
//     "X-Request-Context": context,
//   };
//   const apiClient = await initializeApiClient(customHeaders);
//   try {
//     const response = await apiClient.get(`/${endpoint}`);
//     return response.data;
//   } catch (error) {
//     console.error("Error fetching data:", error);
//     throw error;
//   }
// };

// export const fetchEntryData = async (endpoint, param) => {
//   const apiClient = await initializeApiClient();
//   try {
//     const response = await apiClient.get(`/${endpoint}/${param}`);
//     return response.data;
//   } catch (error) {
//     console.error("Error fetching data:", error);
//     throw error;
//   }
// };

// export const createData = async (endpoint, data) => {
//   const apiClient = await initializeApiClient();
//   try {
//     const response = await apiClient.post(`/${endpoint}`, data);
//     return response.data;
//   } catch (error) {
//     console.error("Error creating data:", error);
//     throw error;
//   }
// };

// export const deleteData = async (endpoint, id) => {
//   const apiClient = await initializeApiClient();
//   try {
//     const response = await apiClient.delete(`/${endpoint}/${id}`);
//     return response.data;
//   } catch (error) {
//     console.error("Error deleting data:", error);
//     throw error;
//   }
// };

// export const deleteMultipleData = async (endpoint, ids) => {
//   const apiClient = await initializeApiClient();
//   try {
//     // Construct the query parameter string
//     const queryString = ids.join(",");
//     const response = await apiClient.delete(
//       `/${endpoint}/delete?ids=${queryString}`
//     );
//     return response.data;
//   } catch (error) {
//     console.error("Error deleting data:", error);
//     throw error;
//   }
// };

// export const updateData = async (endpoint, id, data) => {
//   const apiClient = await initializeApiClient();
//   try {
//     const response = await apiClient.put(`/${endpoint}/${id}`, data);
//     return response.data;
//   } catch (error) {
//     console.error("Error updating data:", error);
//     throw error;
//   }
// };
