import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Spinner, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface NewMaterialModalProps {
  show: boolean;
  onHide: () => void;
  onSubmit: (material: any) => void;
  material?: any;
}

const NewMaterialModal: React.FC<NewMaterialModalProps> = ({
  show,
  onHide,
  onSubmit,
  material,
}) => {
  const { t } = useTranslation();
  const [file, setFile] = useState<File | null>(null);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const allowedExtensions = [
    "pdf",
    "jpg",
    "jpeg",
    "png",
    "gif",
    "bmp",
    "mp4",
    "avi",
    "mov",
    "wmv",
    "flv",
    "mkv",
  ];

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    const fileExtension =
      selectedFile?.name?.split(".").pop()?.toLowerCase() || "";
    if (allowedExtensions.includes(fileExtension)) {
      setFile(selectedFile || null);
      setError(null);
    } else {
      setError(
        "Falsches Dateiformat. PDFs und sämtliche Bild/Videoformate sind erlaubt!"
      );
      setFile(null);
    }
  };

  useEffect(() => {
    if (material) {
      setTitle(material.title);
      setDescription(material.description);
      setCategory(material.category);
    }
  }, [material]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const newMaterial = { file, title, description, category };
    try {
      await onSubmit(newMaterial);
    } catch (error) {
      console.error("Error submitting material:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t("materials.new-material")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>{t("materials.file")}</Form.Label>
            <Form.Control type="file" onChange={handleFileChange} />
          </Form.Group>
          <Form.Group controlId="formTitle" className="mb-3">
            <Form.Label>{t("materials.title")}</Form.Label>
            <Form.Control
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="formDescription" className="mb-3">
            <Form.Label>{t("materials.description")}</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="formCategory" className="mb-3">
            <Form.Label>{t("materials.category")}</Form.Label>
            <Form.Control
              type="text"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required
            />
          </Form.Group>
          <Button
            className="bs-button-color"
            type="submit"
            disabled={loading || (!file && !material)}
          >
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{" "}
                {t("materials.submitting")}
              </>
            ) : material ? (
              t("materials.update")
            ) : (
              t("materials.submit")
            )}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default NewMaterialModal;
