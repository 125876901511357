import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Patients.css";
import "../Programs/Programs.css";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { NewExerciseModal, ProgramArchiveModal } from "../Utils/Modals.js";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { getAccessToken } from "../Utils/Utils.js";
import { handleRemoveImage } from "../Services/imageUtils.js";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AttachFileModal from "../Modals/AttachFileModal.tsx";

export default function PatientHeader(props) {
  const name =
    props.patient && props.patient.name
      ? props.patient.name.split(" ")
      : undefined;
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:900px)");
  const [showNewExerciseModal, setShowNewExerciseModal] = useState(false);
  const [showAttachFileModal, setShowAttachFileModal] = useState(false);
  const [showNewExerciseModalSpinner, setShowNewExerciseModalSpinner] =
    useState(false);
  const [showProgramArchiveModal, setShowProgramArchiveModal] = useState(false);
  const MAX_WIDTH = 300;
  const MAX_FILE_SIZE = 100000;
  const DEFAULT_IMAGE_PATH = "/images/profile_icon.svg";
  const { t } = useTranslation();

  const getProfileImageUrl = (newUrl = null) => {
    if (newUrl) {
      return newUrl;
    } else if (props.patient?.profile_image_url) {
      return process.env.REACT_APP_S3_PREFIX + props.patient.profile_image_url;
    }
    return DEFAULT_IMAGE_PATH;
  };

  const [profileImage, setProfileImage] = useState(getProfileImageUrl());
  const [showRemoveButton, setShowRemoveButton] = useState(
    profileImage !== DEFAULT_IMAGE_PATH
  );

  // adjust and outsource/map to imageUtils.js
  const handleProfileImageChange = async (event) => {
    const file = event.target.files[0];
    console.log("file", file);
    if (file) {
      // Resizing the image
      const fileSizeInKB = file.size / 1024;
      if (fileSizeInKB > 1000) {
        alert(
          "Die Datei ist zu groß. Bitte wählen kleinere Datei mit max. 1MB aus."
        );
        return;
      }
      const reader = new FileReader();
      reader.onload = async (e) => {
        const img = document.createElement("img");
        img.onload = async () => {
          // Create canvas
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          const scale = MAX_WIDTH / img.width;
          canvas.width = MAX_WIDTH;
          canvas.height = img.height * scale;
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          // Convert canvas to blob
          canvas.toBlob(
            async (blob) => {
              if (blob.size <= MAX_FILE_SIZE) {
                setProfileImage(URL.createObjectURL(blob));

                const formData = new FormData();
                formData.append("profileImage", blob);
                formData.append("account_patient_id", props.patient.id);

                const url =
                  process.env.REACT_APP_SOPHYAPP_API_URL +
                  `/de/react/${getAccessToken()}/upload/upload_account_patient_image`;
                const config = {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                };

                try {
                  const response = await axios.post(url, formData, config);
                  const newProfileImageUrl = getProfileImageUrl(
                    response.data.url
                  );
                  setProfileImage(newProfileImageUrl);
                  props.updateProfileImageUrl(newProfileImageUrl);
                } catch (error) {
                  console.error("Error uploading image", error);
                }
              } else {
                console.error("File too large after resizing");
              }
            },
            "image/jpeg",
            0.95
          ); // Adjust quality as needed
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
      event.target.value = "";
    }
  };

  const createNewProgram = () => {
    // window.location.href = "/programs/create";
    navigate("/programs/create", {
      state: {
        account_patient_id: props.patient.id,
        patient_name: props.patient.name,
      },
    });
  };

  useEffect(() => {
    const newImageUrl = getProfileImageUrl();
    setProfileImage(newImageUrl);
  }, [props.patient]);

  useEffect(() => {
    setShowRemoveButton(profileImage !== DEFAULT_IMAGE_PATH);
    if (profileImage == DEFAULT_IMAGE_PATH) {
      props.updateProfileImageUrl(null);
    }
    // const newProfileImageUrl = getProfileImageUrl();
    // props.updateProfileImageUrl(newProfileImageUrl);
  }, [profileImage]);

  var contentMobile = isMobile ? (
    <Row className="profile-card-mobile">
      <center>
        <Row>
          <Col>
            <img
              src="/images/avatar_placeholder.png"
              alt="profile-icon"
              className="profile-card-image"
            />
          </Col>
        </Row>
        <Row>
          <Col className="profile-card-name">
            {name[0]} <b>{name.slice(1).join(" ")}</b>
          </Col>
        </Row>
        <Row>
          <Col className="profile-card-mail">{props.patient.email}</Col>
        </Row>
        <Row>
          <Col className="profile-card-diagnostic">
            {props.patient.diagnostic}
          </Col>
        </Row>
        <Row className="patient-mobile-actions">
          <Col xs={3}>
            <div
              className="patient-mobile-icons"
              onClick={() => {
                props.editPatient();
              }}
            >
              <img
                className="patient-header-btn-icon"
                src="/images/pen.svg"
                alt="edit patient"
              />
              <div className="patient-mobile-icons-text">
                {t("patients.edit-patient-mobile")}
              </div>
            </div>
          </Col>
          <Col xs={3}>
            <div
              className="patient-mobile-icons"
              onClick={() => setShowNewExerciseModal(true)}
            >
              <img
                className="patient-header-btn-icon"
                src="/images/camera.svg"
                alt="film patient"
              />

              <div className="patient-mobile-icons-text">
                {t("patients.film")}
              </div>
            </div>
          </Col>
          <Col xs={3}>
            <div
              className="patient-mobile-icons"
              onClick={() => setShowProgramArchiveModal(true)}
            >
              <img
                className="patient-header-btn-icon"
                src="/images/archive.svg"
                alt="archived programs"
              />

              <div className="patient-mobile-icons-text">
                {t("patients.archive")}
              </div>
            </div>
          </Col>
          <Col xs={3}>
            <div
              className="patient-mobile-icon-new-program"
              onClick={() => createNewProgram()}
            >
              <img
                className="patient-header-btn-icon"
                src="/images/plus.svg"
                alt="add program"
              />

              <div className="patient-mobile-new-program-text">
                {t("patients.new-program-short")}
              </div>
            </div>
          </Col>
        </Row>
      </center>
      <NewExerciseModal
        show={showNewExerciseModal}
        backdrop="static"
        onHide={() => setShowNewExerciseModal(false)}
        setLoader={() => setShowNewExerciseModalSpinner(true)}
        hideLoader={() => setShowNewExerciseModalSpinner(false)}
      />
      <ProgramArchiveModal
        show={showProgramArchiveModal}
        onHide={() => setShowProgramArchiveModal(false)}
        patientId={props.patient.id}
        patientName={props.patient.name}
      />
      <Spinner
        show={showNewExerciseModalSpinner}
        animation="grow"
        role="status"
        style={{
          width: "20rem",
          height: "20rem",
          color: "var(--main-color)",
          top: "30%",
          right: "45%",
          zIndex: "10000",
          display: showNewExerciseModalSpinner ? "block" : "none",
        }}
        className="spinner"
      >
        <span className="visually-hidden"></span>
      </Spinner>
    </Row>
  ) : null;
  var content = name ? (
    <Container className="patient-header-container">
      <Row>
        <Col md={2} xl={1} className="patient-header-image">
          <div style={{ position: "relative", display: "inline-block" }}>
            <label htmlFor={`file-input-${props.patient.id}`}>
              <img
                className="patient-header-icon"
                src={profileImage}
                alt="profile-icon"
                style={{ cursor: "pointer" }}
              />
            </label>
            {showRemoveButton && (
              <button
                onClick={() =>
                  handleRemoveImage(
                    getAccessToken,
                    props.patient.id,
                    setProfileImage,
                    DEFAULT_IMAGE_PATH,
                    "patient"
                  )
                }
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  background: "grey",
                  color: "white",
                  border: "none",
                  borderRadius: "50%",
                  cursor: "pointer",
                  width: "15px",
                  height: "15px",
                  textAlign: "center",
                  lineHeight: "15px",
                  fontSize: "14px",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 0,
                }}
              >
                x
              </button>
            )}
          </div>
          <input
            id={`file-input-${props.patient.id}`}
            type="file"
            accept="image/*"
            onChange={handleProfileImageChange}
            style={{ display: "none" }} // Hide the file input
          />
        </Col>
        <Col md={2} lg={3} className="patient-header-details">
          <Row>
            <Col className="patient-header-details-name">
              {name[0]} <b>{name.slice(1).join(" ")}</b>
            </Col>
          </Row>
          <Row>
            <Col className="patient-header-details-mail">
              {props.patient.email}
            </Col>
          </Row>
          <Row>
            <Col className="patient-header-details-diagnose">
              {props.patient.diagnostic}
            </Col>
          </Row>
        </Col>
        <Col
          md={1}
          lg={2}
          className="patient-header-btn"
          onClick={() => {
            props.editPatient();
          }}
        >
          {t("patients.edit-patient")}
          <img
            className="patient-header-btn-icon"
            src="/images/pen.svg"
            alt="edit patient"
          />
        </Col>
        <Col
          md={1}
          lg={2}
          className="patient-header-btn"
          onClick={() => setShowNewExerciseModal(true)}
        >
          {t("patients.film-patient")}
          <img
            className="patient-header-btn-icon"
            src="/images/camera.svg"
            alt="film patient"
          />
        </Col>
        <Col
          md={1}
          lg={2}
          className="patient-header-btn"
          onClick={() => setShowProgramArchiveModal(true)}
        >
          {t("patients.program-archive")}
          <img
            className="patient-header-btn-icon"
            src="/images/archive.svg"
            alt="archived programs"
          />
        </Col>
        <Col
          md={1}
          lg={2}
          className="patient-header-btn"
          onClick={() => setShowAttachFileModal(true)}
          // style={{ display: "flex", alignItems: "center" }}
        >
          {t("patients.attachments")}
          <AttachFileIcon
            className="patient-header-btn-icon"
            style={{
              color: "#7c878e",
              verticalAlign: "text-bottom",
              width: "20px",
              height: "20px",
            }}
          />
        </Col>
        <Col
          md={1}
          lg={2}
          className="patient-header-btn-new-program"
          href="/programs/create"
          onClick={() => createNewProgram()}
        >
          {t("patients.new-program")}
          <img
            className="patient-header-btn-icon"
            src="/images/plus.svg"
            alt="add program"
          />
        </Col>
      </Row>
      <NewExerciseModal
        show={showNewExerciseModal}
        backdrop="static"
        onHide={() => setShowNewExerciseModal(false)}
        setLoader={() => setShowNewExerciseModalSpinner(true)}
        hideLoader={() => setShowNewExerciseModalSpinner(false)}
        patientId={props.patient.id}
        patientName={props.patient.name}
      />
      <ProgramArchiveModal
        show={showProgramArchiveModal}
        onHide={() => setShowProgramArchiveModal(false)}
        patientId={props.patient.id}
        patientName={props.patient.name}
      />
      {showAttachFileModal && (
        <AttachFileModal
          show={showAttachFileModal}
          patientId={props.patient.id}
          patientName={props.patient.name}
          onHide={() => setShowAttachFileModal(false)}
        />
      )}
      <Spinner
        show={showNewExerciseModalSpinner}
        animation="grow"
        role="status"
        style={{
          width: "20rem",
          height: "20rem",
          color: "var(--main-color)",
          top: "30%",
          right: "45%",
          zIndex: "10000",
          display: showNewExerciseModalSpinner ? "block" : "none",
        }}
        className="spinner"
      >
        <span className="visually-hidden"></span>
      </Spinner>
    </Container>
  ) : null;

  return name ? (isMobile ? contentMobile : content) : null;
}
