import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import "./Exercise.css";
import ReactPlayer from "react-player";
import parse from "html-react-parser";
import Button from "react-bootstrap/Button";
import ShowMoreText from "react-show-more-text";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";
import Alert from "../Utils/Alert";
import { getAccessToken } from "../Utils/Utils.js";
import { useTranslation } from "react-i18next";
import { AddFavoritesToGroup, AddHashtag } from "../Utils/Modals";
import PersonIcon from "@mui/icons-material/Person";
import Tag from "@mui/icons-material/Tag";

export function CatalogExerciseCard(props) {
  const { i18n } = useTranslation();
  var currentLanguage = i18n.language;
  const isMobile = useMediaQuery("(max-width:900px)");
  const smallMobile = useMediaQuery("(max-width:500px");
  const [expanded, setExpanded] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [alertHeader, setAlertHeader] = useState();
  const [alertType, setAlertType] = useState();
  const [loading, setLoading] = useState(false);
  const [videoSrc, setVideoSrc] = useState("/videos/template.mp4");
  const [isSafari, setIsSafari] = useState(false);
  const [controlsSafari, setControlsSafari] = useState(false);
  const [customButtonOpacity, setCustomButtonOpacity] = useState(0.7);
  const [addFavoritesToGroupModal, setAddFavoritesToGroupModal] =
    useState(false);
  const [addHashtagModal, setAddHashtagModal] = useState(false);
  var indexTranslation = 0;
  if (
    props.exercise.source_translations &&
    props.exercise.source_translations.length > 1
  ) {
    currentLanguage == "en-US"
      ? (currentLanguage = "en")
      : (currentLanguage = currentLanguage);
    indexTranslation = props.exercise.source_translations.findIndex(
      (element) => element.locale === currentLanguage
    );
  }
  var fullText = parse(
    "<li>" +
      (props?.exercise?.mapping_sources_translation?.[0]?.steps
        ? props.exercise.mapping_sources_translation[0].steps.replace(
            /<br \/>/g,
            "</li><li>"
          )
        : props?.exercise?.source_translations?.[
            indexTranslation
          ]?.steps?.replace(/<br \/>/g, "</li><li>")) +
      "</li>"
  );

  // var previewText = parse(
  //   "<li>" +
  //     props.exercise.source_translations[0].steps
  //       .substring(0, 100)
  //       .replace(/<br \/>/g, "</li><li>") +
  //     "</li>"
  // );

  function getWidth() {
    return window.innerWidth;
  }

  const totalCharacters = async () => {
    if (props.exercise.source_translations[indexTranslation]) {
      props.exercise.source_translations[indexTranslation].steps.reduce(
        (acc, step) => {
          return acc + step.length;
        },
        0
      );
    }
  };

  const addToFavorites = async (id) => {
    const url =
      process.env.REACT_APP_SOPHYAPP_API_URL +
      `/de/react/${getAccessToken()}/sources/add_to_favorites/${id}`;
    axios
      .get(url)
      .then((response) => {
        setShowAlert(true);
        setAlertText("Die Änderung wurde gespeichert.");
        setAlertHeader("Favoritenänderung gespeichert");
        setAlertType("success");
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      })
      .catch(() => {
        setShowAlert(true);
        setAlertText("Die Änderung wurde nicht gespeichert.");
        setAlertHeader("Favoritenänderung nicht gespeichert");
        setAlertType("danger");
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      });
  };
  let cardFooter = (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <Tag
        style={{ color: "lightgrey", marginRight: "5px", cursor: "pointer" }}
        onClick={(e) => setAddHashtagModal(true)}
      />
      <img
        src="/images/favorite.png"
        alt="favorite"
        style={{ cursor: "pointer", color: "lightgrey" }}
        onClick={(e) => setAddFavoritesToGroupModal(true)}
      />
    </div>
  );
  if (props.button) {
    cardFooter = (
      <Button
        className="bs-button-color plus-icon-add-ex-wrapper"
        type="submit"
        onClick={(e) =>
          props.addExerciseToList(
            props.exercise.id,
            props.exercise?.mapping_sources_translation[0]?.name
              ? props.exercise.mapping_sources_translation[0].name
              : props.exercise.source_translations[indexTranslation]
              ? props.exercise.source_translations[indexTranslation].name
              : "",
            props.exercise.thumbnail,
            props.exercise?.mapping_sources_translation[0]?.steps
              ? props.exercise.mapping_sources_translation[0].steps
              : props.exercise.source_translations[indexTranslation]
              ? props.exercise.source_translations[indexTranslation].steps
              : ""
          )
        }
      >
        {/* {isMobile ? null : getWidth() <= 1700 ? null : "Hinzufügen"} */}
        <img className="plus-icon-add-ex" src="/images/plus.svg" />
      </Button>
    );
  }

  const handlePlayPause = (event) => {
    const videoElement = event.currentTarget.nextSibling;
    if (videoElement.paused) {
      videoElement.play();
      setControlsSafari(true);
      setCustomButtonOpacity(0);
    } else {
      videoElement.pause();
      setControlsSafari(false);
      setCustomButtonOpacity(0.7);
    }
  };
  function isSafariUser() {
    return (
      /^((?!chrome|android).)*safari/i.test(navigator.userAgent) ||
      (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream)
    );
  }

  // useEffect(() => {
  //   const isSafariBrowser = /^((?!chrome|android).)*safari/i.test(
  //     navigator.userAgent
  //   );
  //   setIsSafari(isSafariBrowser);
  // }, []);

  // Detect if the browser is Safari
  useEffect(() => {
    const safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    setIsSafari(safari);
  }, []);

  return (
    <Container className="catalog-exercise-card mobile-width">
      <div className="exercise-card-header">
        <Row>
          <Col
            className="exercise-title"
            style={{ cursor: "pointer" }}
            onClick={() => {
              props.setActiveExercise(props.exercise);
              props.setShowExerciseModal(true);
              props.setExercise({
                title:
                  props.exercise.source_translations[indexTranslation].name,
                steps:
                  props.exercise.source_translations[indexTranslation].steps,
                thumbnail: props.exercise.thumbnail,
                video: props.exercise.video,
                patient_id: props.exercise.patient_id,
                body_region: props.exercise.body_region,
                difficulty: props.exercise.difficulty,
                starting_position: props.exercise.starting_position,
                goal: props.exercise.goal,
                tool: props.exercise.tool,
              });
            }}
          >
            {props?.exercise?.mapping_sources_translation?.[0]?.name
              ? props.exercise.mapping_sources_translation[0].name
              : props?.exercise?.source_translations?.[indexTranslation]?.name
              ? parse(props.exercise.source_translations[indexTranslation].name)
              : ""}
          </Col>
        </Row>
        <Row>
          <Col className="body-region-title">{props.exercise.body_region} </Col>
        </Row>
      </div>
      <Row>
        <Col className="exercise-video-player">
          <center>
            {/* <ReactPlayer url="{props.exercise.source.video_url}" light={props.exercise.source.thumb_url} /> */}
            <div className={`video-container${isSafari ? " safari-fix" : ""}`}>
              {/* Custom play button for Safari */}
              {isSafari && (
                <div
                  className="custom-play-button"
                  onClick={handlePlayPause}
                  style={{ opacity: customButtonOpacity }}
                ></div>
              )}
              <video
                loop
                controls={isSafari ? (controlsSafari ? true : false) : true}
                controlsList="nodownload"
                poster={
                  process.env.REACT_APP_SOPHYAPP_THUMBS_URL +
                  (props.exercise.thumbnail || props.exercise.source.thumbnail)
                }
                src={
                  process.env.REACT_APP_SOPHYAPP_VIDEO_URL +
                  (props.exercise.video || props.exercise.source.video)
                }
                className="catalog-video-content"
                preload="none"
              ></video>
            </div>
          </center>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="exercise-description">
          <ul className={`text-div ${expanded ? "" : "truncate"}`}>
            {expanded ? fullText : null}
          </ul>
          {/* {(props.exercise.source_translations[0].steps.length >
            (smallMobile ? 2 : isMobile ? 3 : 4) ||
            totalCharacters > (smallMobile ? 150 : isMobile ? 200 : 250)) && ( */}
          <Row className="show-more-button">
            <span
              className="show-more-text"
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? "Weniger anzeigen" : "Mehr anzeigen"}
            </span>
          </Row>
          {/* )} */}
        </Col>
      </Row>
      <Row>
        <Col md={12} className="catalog-exericse-actions">
          <Row>
            <Col xs={4} md={4}>
              {props.exercise.provider == "sophyapp" ? (
                <img
                  src="/favicon.ico"
                  alt="favorite"
                  className="provider-image"
                />
              ) : (
                ""
              )}
              {props.exercise.patient_id && (
                <PersonIcon
                  titleAccess={`verknüpft mit Patienten ID ${props.exercise.patient_id}`}
                  sx={{ color: "grey" }}
                />
              )}
            </Col>
            <Col xs={8} md={8}>
              {cardFooter}
            </Col>
          </Row>
        </Col>
      </Row>
      <Alert
        variant={alertType}
        showAlert={showAlert}
        className="alert-fixed"
        onClose={() => setShowAlert(false)}
        setShowAlert={() => setShowAlert(false)}
        header={alertHeader}
        main={alertText}
        dismissible
      ></Alert>
      <AddFavoritesToGroup
        show={addFavoritesToGroupModal}
        exercise={props.exercise}
        onHide={() => setAddFavoritesToGroupModal(false)}
      />
      <AddHashtag
        show={addHashtagModal}
        exercise={props.exercise}
        onHide={() => setAddHashtagModal(false)}
      />
    </Container>
  );
}

export function ExerciseCard(props) {
  const isMobile = useMediaQuery("(max-width:900px)");
  const smallMobile = useMediaQuery("(max-width:500px");
  const [isSafari, setIsSafari] = useState(false);
  const [controlsSafari, setControlsSafari] = useState(false);
  const [customButtonOpacity, setCustomButtonOpacity] = useState(0.7);
  const totalCharacters = props.exercise.steps.reduce((acc, step) => {
    return acc + step.length;
  }, 0);

  var fullText = (
    <ul>
      {props.exercise.mapping_steps && props.exercise.mapping_steps !== ""
        ? props.exercise.mapping_steps
            .split("</br>")
            .map((step, index) => <li key={index}>{parse(step)}</li>)
        : props.exercise.steps.map((step, index) => (
            <li key={index}>{parse(step)}</li>
          ))}
    </ul>
  );

  const handlePlayPause = (event) => {
    const videoElement = event.currentTarget.nextSibling;
    if (videoElement.paused) {
      videoElement.play();
      setControlsSafari(true);
      setCustomButtonOpacity(0);
    } else {
      videoElement.pause();
      setControlsSafari(false);
      setCustomButtonOpacity(0.7);
    }
  };

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    setIsSafari(safari);
  }, []);

  return (
    <Container className="exercise-card mobile-width">
      <div className="exercise-card-header">
        <Row>
          <Col className="exercise-title">
            {parse(
              props.exercise.mapping_name
                ? props.exercise.mapping_name
                : props.exercise.name
            )}
          </Col>
        </Row>
        <Row>
          <Col className="body-region-title">{props.exercise.body_region}</Col>
        </Row>
      </div>
      <Row>
        <Col className="exercise-video-player">
          <center>
            <div className={`video-container${isSafari ? " safari-fix" : ""}`}>
              {/* Custom play button for Safari */}
              {isSafari && (
                <div
                  className="custom-play-button"
                  onClick={handlePlayPause}
                  style={{ opacity: customButtonOpacity }}
                ></div>
              )}
              {/* <ReactPlayer url="{props.exercise.source.video_url}" light={props.exercise.source.thumb_url} /> */}
              <video
                controls={isSafari ? (controlsSafari ? true : false) : true}
                loop
                src={props.exercise.video_url}
                poster={props.exercise.thumb_url}
                className="video-content"
                preload="none"
              >
                Sorry, your browser doesn't support embedded videos, but don't
                worry, you can <a href="/">download it</a>
                and watch it with your favorite video player!
              </video>
            </div>
          </center>
        </Col>
      </Row>
      <Row>
        <Col className="prescription">{props.exercise.prescription}</Col>
      </Row>
      <Row>
        <Col xs={12} className="exercise-description">
          <div className={`text-div ${expanded ? "" : "truncate"}`}>
            {expanded ? fullText : null}
          </div>
        </Col>
        {/* {(props.exercise.steps.length > (smallMobile ? 2 : isMobile ? 3 : 4) ||
          totalCharacters > (smallMobile ? 150 : isMobile ? 200 : 250)) && ( */}
        <Row className="show-more-button">
          <span
            className="show-more-text"
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? "Weniger anzeigen" : "Mehr anzeigen"}
          </span>
        </Row>
        {/* )} */}
      </Row>
      <Row>
        <Col className="exercise-note">{props.exercise.note}</Col>
      </Row>
    </Container>
  );
}
