export const isArrayEmpty = (arr) => {
  if (arr !== undefined && arr !== null && arr.length > 0) {
    return true;
  }
  return false;
};

export const getAccessToken = () => {
  var object = JSON.parse(localStorage.getItem("access_token"));
  if (object == null) {
    return null;
  }
  var dateString = null;
  var now = null;
  if (object) {
    dateString = object.expiration;
    now = new Date().getTime().toString();
  }
  if (!object || now > dateString) {
    if (object.userToken !== undefined) {
      localStorage.removeItem(object);
      window.location.href = "/login";
      return null;
    }
    // return window.location.reload();
    return null;
  }
  return object.userToken === "undefined" || object.userToken === undefined
    ? null
    : object.userToken;
  //
  // const accessToken = localStorage
  //   .getItem("access_token")
  //   .userToken.replaceAll('"', "");
  // return accessToken === "undefined" || accessToken === undefined
  //   ? null
  //   : accessToken;
};

export const isActive = (date, numWeeks) => {
  const createdAtDate = new Date(date);
  if (
    createdAtDate.setDate(createdAtDate.getDate() + numWeeks * 7) > new Date()
  ) {
    return true;
  }
  return false;
};

export const stableSort = (array, cmp) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

export const getSorting = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
};

export const desc = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return 1;
  }
  if (b[orderBy] > a[orderBy]) {
    return -1;
  }
  return 0;
};

const dateOptions = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
  hour12: false,
};

export const dateFormatter = new Intl.DateTimeFormat("de-DE", dateOptions);

export const getEndDateOfProgram = (date, numWeeks) => {
  const createdAtDate = new Date(date);
  return createdAtDate.setDate(createdAtDate.getDate() + numWeeks * 7);
};

export const programInterval = (param) => {
  switch (param) {
    case "7":
      return "täglich";
    default:
      return param + "mal pro Woche";
  }
};

export const getTargetName = (param) => {
  switch (param) {
    case "1":
      return "Schmerzlinderung";
    case "2":
      return "Kräftigung";
    case "3":
      return "Beweglichkeit";
    case "4":
      return "Koordination";
    case "5":
      return "Prävention";
    case "6":
      return "Entspannung";
    case "7":
      return "Asudauer";
    default:
      return "Schmerzlinderung";
  }
};
