import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface SidebarProps {
  setShowNewExerciseModal: (show: boolean) => void;
  setParams: (params: any) => void;
  handleOnChangeFilter: (name: string, value: string) => void;
  filters: React.ReactNode;
  sideBarHeaderTitle?: string;
  modalButtonTitle?: string;
  searchPlaceholder?: string;
}
const Sidebar: React.FC<SidebarProps> = ({
  setShowNewExerciseModal,
  setParams,
  handleOnChangeFilter,
  filters,
  sideBarHeaderTitle,
  modalButtonTitle,
  searchPlaceholder,
}) => {
  const { t } = useTranslation();

  return (
    <Col md={2} xxl={2} xxxl={1} className="sidebar-properties h-100">
      <div className="fixed-position">
        <Row>
          <Col md={12} className="sidebar-header">
            {sideBarHeaderTitle || t("exercises.exercises")}
          </Col>
        </Row>
        <Row>
          <Col md={12} className="">
            <button
              type="submit"
              className="btn-new-program"
              onClick={() => setShowNewExerciseModal(true)}
              style={{ cursor: "pointer" }}
            >
              {window.innerWidth <= 1400
                ? t("exercises.add")
                : modalButtonTitle || t("exercises.addExercise")}
              <img
                className="plus-icon"
                src="/images/plus.svg"
                alt="add exercise"
              />
            </button>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <input
              type="search"
              name="q"
              className="searchbar-exercises d-none d-sm-block"
              placeholder={
                searchPlaceholder || t("exercises.search").toString()
              }
              // @ts-ignore
              value={setParams?.q}
              onChange={(e) =>
                handleOnChangeFilter(e.target.name, e.target.value)
              }
            ></input>
          </Col>
        </Row>
        {filters}
      </div>
    </Col>
  );
};

export default Sidebar;
